import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  cartTotalPrice: number = 1;
  showPaypal: boolean = false;
  isTransactionComplete: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  checkout(){
    this.showPaypal = true;
  }

  paymentApprove(event){
    console.log(event);
    if(event.status == 'COMPLETED'){
        console.log("okkkk");
      this.isTransactionComplete = true;
    }
  }

}
